"use client";

import usePath from "../app/hooks/usePath";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useState, useContext, ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Authenticate from "../components/Authenticate";
import { FaCircleUser, FaGear } from "react-icons/fa6";
import { SessionContext } from "../app/hooks/useSession";
import { useRouter } from "next/navigation";
import Image from "next/image";
import { Breadcrumbs, BreadcrumbElementType } from "./Breadcrumbs";
import { FaExclamation, FaQuestionCircle, FaSignOutAlt } from "react-icons/fa";
import { TeamsContext } from "../app/hooks/useTeams";
import { GamesContext } from "../app/hooks/useGames";
import { MdTableRows as IconPanelVisibility } from "react-icons/md";
import { PanelVisibilityTeamWarning } from "./panels/PanelController";
import { OffcanvasUserPrefs } from "./offcanvas/OffcanvasUserPrefs";
import { PiHandHeartFill } from "react-icons/pi";
import { UserContext } from "../app/hooks/useUsers";

export default function AppNavigation({ path }: { path?: string }) {
  const { team } = useContext(TeamsContext);
  const {
    user: sessionUser,
    isLoading: isLoadingSession,
    signOut,
    userHasRoles,
    editPanels,
    toggleEditPanels,
  } = useContext(SessionContext);
  const { games } = useContext(GamesContext);
  const { user } = useContext(UserContext);
  const [showEditPanelsWarning, setShowEditPanelsWarning] = useState(false);

  const { game_id } = usePath();
  var game;
  if (games != null) {
    game = games.find((g) => g.id === game_id);
  }

  const [showUserPrefs, setShowUserPrefs] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const {
    pathname,
    isPathGames,
    isPathGame,
    isPathReport,
    isPathMerge,
    isPathValidate,
    isPathTeamSettings,
    isPathSupport,
    isPathSupportSuccess,
  } = usePath();
  const router = useRouter();

  const onSignOut = () => {
    signOut();
    router.push(window.location.pathname);
    router.refresh();
  };

  let breadcrumbs: BreadcrumbElementType[] = [{ label: "Home", href: "/" }];
  if (
    isLoadingSession === "Session Ready" ||
    isLoadingSession == "Session Teams Ready"
  ) {
    if (
      isPathGames ||
      isPathGame ||
      isPathReport ||
      isPathMerge ||
      isPathValidate ||
      isPathTeamSettings
    ) {
      breadcrumbs.push({
        label: "Team",
        href: pathname.match(/\/t\/\d+/g)?.[0],
      });

      if (isPathGame) {
        breadcrumbs.push({ label: "Game" });
      } else if (
        isPathReport ||
        isPathMerge ||
        isPathValidate ||
        isPathTeamSettings
      ) {
        breadcrumbs.push({
          label: isPathReport
            ? "Report"
            : isPathMerge
            ? "Merge"
            : isPathValidate
            ? "Validate"
            : "Manage",
        });
      }
    } else if (isPathSupport) {
      breadcrumbs.push({
        label: "Support",
      });
    } else if (isPathSupportSuccess) {
      breadcrumbs.push({
        label: "Support",
        href: "/support/",
      });
      breadcrumbs.push({
        label: "Success",
      });
    }
  }

  // team name available: sessionTeams?.[pathname.match(/\/t\/(\d+)\/?.*$/)?.[1]]?.name
  return (
    <AppNavigationWrapper breadcrumbs={breadcrumbs}>
      {isLoadingSession === "Session Ready" ||
      isLoadingSession == "Session Teams Ready" ? (
        <Col xs={6} className="d-flex justify-content-end px-0">
          {sessionUser !== null ? (
            <Nav>
              <NavDropdown
                className="d-flex align-items-center justify-content-end"
                align="end"
                title={
                  sessionUser?.user_metadata?.hasOwnProperty("avatar_url") ? (
                    <>
                      <span className="text-secondary">
                        {user?.name ??
                          sessionUser?.email?.match(/^([^@]*)@/)[1]}
                      </span>
                      &nbsp;
                      <Image
                        src={sessionUser?.user_metadata?.avatar_url}
                        referrerPolicy="no-referrer"
                        alt="User"
                        width={30}
                        height={30}
                        className="shadow-sm border"
                        style={{
                          verticalAlign: "middle",
                          borderRadius: "50%",
                        }}
                      />
                    </>
                  ) : (
                    <FaCircleUser className="text-secondary" />
                  )
                }>
                {sessionUser !== null ? (
                  <>
                    <NavDropdown.Item
                      onClick={() => setShowUserPrefs(true)}
                      className="d-flex py-1 align-items-center">
                      <FaGear className="me-1 text-muted opacity-75" />
                      Preferences
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => onSignOut()}
                      className="d-flex py-1 align-items-center">
                      <FaSignOutAlt className="me-1 text-muted opacity-75" />
                      Sign Out
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                ) : null}
                {isPathGame &&
                team !== null &&
                userHasRoles(team.id).admin &&
                game !== null &&
                game?.ended_at !== null ? (
                  <>
                    <NavDropdown.Item
                      onClick={() => {
                        !editPanels && setShowEditPanelsWarning(true);
                        toggleEditPanels();
                      }}
                      className="d-flex py-1 align-items-center">
                      <IconPanelVisibility className="me-1 text-muted opacity-75" />
                      Panel Visibility
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                ) : null}
                <NavDropdown.Item
                  href="/new/"
                  className="d-flex py-1 align-items-center">
                  <FaExclamation className="me-1 text-warning opacity-75" />
                  What&rsquo;s New
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/support/"
                  className="d-flex py-1 align-items-center">
                  <PiHandHeartFill className="me-1 text-success opacity-75" />
                  Contribute
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="/help/"
                  className="d-flex py-1 align-items-center">
                  <FaQuestionCircle className="me-1 text-muted opacity-75" />
                  Help
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <FaCircleUser
              className="fs-5 text-secondary"
              onClick={() => setShowAuthModal(true)}
            />
          )}
        </Col>
      ) : null}

      <PanelVisibilityTeamWarning
        show={showEditPanelsWarning}
        delay={6000}
        onClose={() => setShowEditPanelsWarning(false)}
      />

      <OffcanvasUserPrefs
        show={showUserPrefs}
        onClose={() => setShowUserPrefs(false)}
      />

      <Authenticate
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </AppNavigationWrapper>
  );
}

const AppNavigationWrapper = ({
  breadcrumbs,
  children,
}: {
  breadcrumbs: BreadcrumbElementType[];
  children?: ReactNode;
}) => (
  <Navbar className="py-0" style={{ height: 55 }}>
    <Container fluid className="px-0">
      <Row className="w-100 mx-0">
        <Col xs={6} className="px-0 d-flex align-items-center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Col>
        {children}
      </Row>
    </Container>
  </Navbar>
);

export const AppNavigationPlaceholder = () => (
  <AppNavigationWrapper breadcrumbs={[]} />
);
