import { Button, Form, InputGroup } from "react-bootstrap";
import { IconCircleUncheck } from "./icons";

const FormText = ({
  value,
  pattern = "",
  placeholder = "",
  inputType = "text",
  clearButton = true,
  disabled = false,
  readOnly = false,
  validated = false,
  invalidMessage = null,
  required = false,
  setValue,
}: {
  value: string;
  pattern?: string;
  placeholder?: string;
  inputType?: "text" | "email";
  clearButton?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  validated?: boolean;
  invalidMessage?: string;
  required?: boolean;
  setValue: (value: string) => void;
}) => {
  const showClearButton =
    value.length > 0 && clearButton && !disabled && !readOnly;
  return (
    <InputGroup>
      <Form.Control
        type={inputType}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        pattern={pattern}
        placeholder={placeholder}
        value={value}
        className={showClearButton ? "" : "rounded-end"}
        isInvalid={
          validated && pattern && !new RegExp(pattern).test(value ?? "")
        }
        onChange={(e) => setValue(e.target.value)}
      />
      {showClearButton ? (
        <Button
          onClick={() => setValue("")}
          className="bg-light text-muted border border-secondary rounded-end">
          <IconCircleUncheck />
        </Button>
      ) : null}
      {invalidMessage ? (
        <Form.Control.Feedback type="invalid">
          {invalidMessage}
        </Form.Control.Feedback>
      ) : null}
    </InputGroup>
  );
};
export default FormText;
