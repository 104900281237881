import Button from "react-bootstrap/Button";
import { Col, Form, Offcanvas, Row } from "react-bootstrap";
import { FaCircleUser } from "react-icons/fa6";
import { SessionContext } from "../../app/hooks/useSession";
import { FormEvent, useContext, useEffect, useState } from "react";
import Image from "next/image";
import FormText from "../FormText";
import { User, UserContext } from "../../app/hooks/useUsers";
import { isEqual } from "lodash";
import { TeamsContext } from "../../app/hooks/useTeams";

export const OffcanvasUserPrefs = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const {
    user: sessionUser,
    sessionTeams,
    updateTeamName,
  } = useContext(SessionContext);
  const { team } = useContext(TeamsContext);
  const { user, updateUser } = useContext(UserContext);
  const [cachedUser, setCachedUser] = useState<User | null>(null);
  const [cachedUsernameTeam, setCachedUsernameTeam] = useState<string>("");
  const [validated, setValidated] = useState(false);
  const hasChanged = !isEqual(user, cachedUser);

  const usernameTeamHasChanged =
    team &&
    sessionTeams?.[team?.id] &&
    cachedUsernameTeam !== sessionTeams?.[team?.id]?.username;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission behavior
    event.preventDefault();
    // set the form to validated
    setValidated(true);
    // validate the form contents and abort update if validation fails
    if (event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    await updateUser({
      ...user,
      name: cachedUser.name?.trim(),
      opt_in: cachedUser.opt_in,
    });

    // if team username has changed, update it in the `user_team` table
    if (team && usernameTeamHasChanged) {
      updateTeamName(sessionTeams?.[team?.id], cachedUsernameTeam);
    }

    onClose();
  };

  return (
    <Offcanvas
      show={show}
      className="responsive-offcanvas"
      placement="bottom"
      onEnter={() => {
        setCachedUser(user);
        setCachedUsernameTeam(sessionTeams?.[team?.id]?.username ?? "");
        setValidated(false);
      }}
      onExited={() => {
        setCachedUser(null);
        setCachedUsernameTeam("");
      }}
      onHide={onClose}>
      {cachedUser !== null ? (
        <Offcanvas.Body className="pt-0 overflow-visible">
          <Row className="d-flex flex-column mb-3">
            <Col className="d-flex justify-content-center align-item-center">
              {sessionUser?.user_metadata?.hasOwnProperty("avatar_url") ? (
                <Image
                  src={sessionUser?.user_metadata?.avatar_url}
                  referrerPolicy="no-referrer"
                  alt="User"
                  width={80}
                  height={80}
                  className="shadow-sm border position-relative"
                  style={{
                    borderRadius: "50%",
                    marginTop: "-25px",
                    zIndex: 10,
                  }}
                />
              ) : (
                <FaCircleUser
                  className="text-muted opacity-75 mt-2"
                  style={{ fontSize: "3em" }}
                />
              )}
            </Col>
            <Col className="d-flex justify-content-center align-item-center mt-1">
              <small className="text-muted">{user?.email}</small>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-2">
              <Col xs={12} sm={4} className="d-flex align-items-center">
                Name
              </Col>
              <Col xs={12} sm={8}>
                <FormText
                  value={cachedUser?.name ?? ""}
                  setValue={(value: string) =>
                    setCachedUser({ ...cachedUser, name: value })
                  }
                  pattern="^$|.{3,}"
                  validated={validated}
                  invalidMessage="Name must be at least 3 characters."
                  required
                />
              </Col>
            </Form.Group>
            {team && sessionTeams?.[team?.id] ? (
              <>
                <Form.Group as={Row} className="mt-2">
                  <Col xs={12} sm={4} className="d-flex align-items-center">
                    <span className="d-sm-none">{team.name} Nickname</span>
                    <span className="d-none d-sm-inline">Chat Name</span>
                  </Col>
                  <Col xs={12} sm={8}>
                    <FormText
                      value={cachedUsernameTeam}
                      placeholder={cachedUser?.name ?? ""}
                      setValue={(value: string) => setCachedUsernameTeam(value)}
                      pattern="^$|.{3,}"
                      validated={validated}
                      invalidMessage="Name must be at least 3 characters."
                    />
                  </Col>
                </Form.Group>
                <small className="d-block text-muted mt-1">
                  Nickname in&nbsp;
                  <span className="d-sm-none">team&rsquo;s</span>
                  <span className="d-none d-sm-inline fw-bolder">
                    {team.name}
                  </span>
                  &nbsp;chat, overrides Name (optional)
                </small>
              </>
            ) : null}
            <Row className="mt-2">
              <Col xs={6} className="d-flex align-items-center lh-sm">
                Email Opt In
              </Col>
              <Col
                xs={6}
                className="d-flex align-items-center justify-content-end">
                <Form.Check
                  type="switch"
                  value={cachedUser?.name ?? ""}
                  checked={cachedUser?.opt_in}
                  onChange={() =>
                    setCachedUser((previous) => ({
                      ...previous,
                      opt_in: !previous.opt_in,
                    }))
                  }
                />
              </Col>
            </Row>
            <small className="d-block text-muted">
              Let us keep you up to date when we make big changes
            </small>
            <Row className="mt-4 gx-2">
              <Col xs={6}>
                <Button
                  variant="outline-secondary"
                  className="w-100"
                  onClick={onClose}>
                  Close
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="w-100"
                  variant="primary"
                  type="submit"
                  disabled={!hasChanged && !usernameTeamHasChanged}>
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      ) : null}
    </Offcanvas>
  );
};
